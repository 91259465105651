/**
 * EventTable
 * @brief  BedEvent Table
 * @author [Jeremy Aftem]
 * @notes
 */

import React, { } from 'react';
import {
  EventTableLayout,
} from './styles';
import EventTableHeader from './EventTableHeader';
import EventTableRow from './EventTableRow';

const EventTable = ({ data }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <EventTableLayout style={{overflow: 'scroll'}}>
      <EventTableHeader columns={data.columns} />
      {
        data.rows.map((row, idx) => {
          return (
            <EventTableRow key={idx} columns={data.columns} row={row} />
          )
        })
      } 
    </EventTableLayout>
  );
};

export default EventTable;