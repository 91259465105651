/**
 * BedPopup
 * @brief  BedPopup component
 * @author [Jeremy Aftem]
 * @notes
 */

import React, { useEffect, useState, useRef } from 'react';
import PopupModal from '../../../../../../components/library/PopupModal';
import { LayoutBox } from '../../../../../../components/layout/styles';
import {
  BedPopupCard,
  BedPopupBox,
  BedPopupHeader,
  BedPopupSub,
  BedPopupBody,
 } from './styles';
 import useVitalacyAPI from '../../../../../../hooks/useVitalacyAPI';
 import EventTable from '../../../layout/EventTable';
 import { convertDateToString } from '../../../utils/strings';
import {
  EVENT_TYPE,
  TEXT_TYPE,
} from '../../../layout/EventTable/EventTableRow/constants';
import AlertsTable from '../../../Alerts/AlertsTable';

const BedPopup = ({ user, alerts, bedData, isOpen, onClose }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [historyCall, doHistoryCall] = useVitalacyAPI(
    {method: 'POST'},
    {manual: true},
  );
  const [myAlert, setMyAlert] = useState([]);
  const [isReady, setReady] = useState(false);
  const attended = useRef([]);
  const [history, setHistory] = useState([]);
  const [table, setTable] = useState({
    columns: [
      {
        name: 'EVENT',
        type: EVENT_TYPE,
        flexGrow: 0,
        width: '115px',
        style: null,
      },
      {
        name: 'DETAILS',
        type: TEXT_TYPE,
        flexGrow: 1,
        width: null,
        style: null,
      },
      {
        name: 'TIME/DATE',
        type: TEXT_TYPE,
        flexGrow: 0,
        style: {
          justifyContent: 'right',
          alignText: 'right',
          marginRight: '6px'
        }
      },
    ],
    rows: [],
  });

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  useEffect(() => {
    let hasAlert = false;
    let toUpdate;
    alerts.forEach((alert) => {
      if (alert.roomDeviceId === bedData.id) {
        hasAlert = true;
        toUpdate = alert;
      }
    });
    if (hasAlert) {
      setMyAlert([toUpdate]);
    }
  }, [alerts, bedData.id, setMyAlert]);

  const setAttended = (value) => {
    attended.current = value;
  }

  useEffect(() => {
    if (isOpen) {
      doHistoryCall({
        url: '/bedevent/history',
        data: {
          groupId: user.groupId,
          roomDeviceId: bedData.id,
          limit: 5
        },
      });
    }
  }, [isOpen, doHistoryCall, user, bedData]);

  useEffect(() => {
    if (historyCall.isLoaded) {
      setHistory([...historyCall.data]);
      if (isOpen) {
        setReady(isOpen);
      }
    }
  }, [historyCall, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setReady(isOpen);
    }
  }, [isOpen])

  useEffect(() => {
    let rows = [];
    history.forEach((item) => {
      let cols = [
        {text: item.bedState},
        {text: item.message},
        {text: convertDateToString(item.createdAt)},
      ];
      rows.push(cols);
    });
    setTable({
      columns: table.columns,
      rows,
    });
  }, [history, setTable, table.columns]);

  useEffect(() => {

  }, []);


  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/
  const onClosePopup = () => {
    attended.current = [];
    setHistory([]);
    onClose();
  };

  /*--------------------------------------------------------------------------*/
  /* Render
  /*--------------------------------------------------------------------------*/
  const render = () => {
    if (!bedData) return <div />
    return (
      <div>
        <PopupModal
          open={isReady}
          onClose={()=>{onClosePopup()}}
        >
          <BedPopupCard>
            <BedPopupBox>
              <LayoutBox col>
                <BedPopupHeader>
                  { bedData.room.name }
                </BedPopupHeader>
                <BedPopupBody>
                  {myAlert.length ? (
                    <>
                      <BedPopupSub>
                        Please respond to the following urgent alerts:
                      </BedPopupSub>
                      <AlertsTable
                        user={user}
                        alerts={myAlert}
                        isOpen={isOpen}
                        attended={attended.current}
                        setAttended={setAttended}
                      />
                    </>
                  ): null}
                  <BedPopupSub>
                    Recent bed event history:
                  </BedPopupSub>
                 <EventTable data={table} />
                </BedPopupBody>
              </LayoutBox>
            </BedPopupBox>
          </BedPopupCard>
        </PopupModal>
      </div>
   );
  };
  return render();
};

export default BedPopup;
