import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../../components/layout/styles';

export const EventTableLayout = styled(LayoutBox)`
  width: 100%;
  height: 100%; 
  overflow-y:scroll;
`;

export const TableHeader = styled(LayoutBox)`
  background-color: #FAFAFA;
  width: ${props => props.width};
`;

export const TableHeaderCol = styled(LayoutBox)`
  color: #545454;
  font-size: 12px;
  padding: 10px;
  flex-grow: ${props => props.flexgrow};
  width: ${props => props.width}
`;

export const TableRow = styled(LayoutBox)`
`;

export const TableCol = styled(LayoutBox)`
  font-size: 14.5px;
  padding: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #545454;
  width: ${props => props.width};
  flex-grow: ${props => props.flexgrow};
`;

export const EventWrapper = styled.div`
  margin-left: 0px;
  font-size: 12.5px;
  font-weight: 600;
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 3px; 
`;
