import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../../../components/layout/styles';

export const BedPopupCard = styled(LayoutBox)`
  width: 40vw;
  max-height: 90vh;
  min-width: 800px;
  background: #fff;
  border-top: 8px solid #206df6;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
`;

export const BedPopupBox = styled(LayoutBox)`
  padding: 24px;
`;

export const BedPopupHeader = styled(LayoutBox)`
  font-size: 26px;
  font-weight: 500;
  color: #545454;
  flex-grow: 1;
`;

export const BedPopupSub = styled(LayoutBox)`
  color: #545454;
  padding-bottom: 16px;
  padding-top: 16px;
  flex-grow: 1;
`;

export const BedPopupBody = styled(LayoutBox)`
  padding-top: 24px;
  font-size: 16px;
  color: #545454;
  flex-grow: 1;
`;